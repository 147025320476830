import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../../../components/PageWrapper";
import Hero from "../../../sections/courses/Hero";
import Main from "../../../sections/courses/Main";
import Features from "../../../sections/common/Features";
import Demo from "../../../sections/common/Demo";
import Wave from "../../../sections/common/Wave";
import WaveReverse from "../../../sections/common/WaveReverse";
import CTA from "../../../sections/CTA/CTA";
import SEO from '../../../components/SEO';
import metaImg from "../../../assets/image/metaimg/courses.jpg"
const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Interactive Online Courses"
          description="Education and learning rely on distribution of information. Interactive videos can provide this in abundance."
          image={metaImg}
        />            
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/learning">Learning</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li> 
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/learning/courses">Courses</Link>
              </li>                            
              </ol>
           </nav>
        </div>           
        <Hero />
        <Wave color="#F7F9FC" /> 
        <Main className="grey" />
        <WaveReverse pos="center" color="#0c426d" background="#F7F9FC" /> 
        <Demo className="bg-digma-dark mb-20" />
        <Features />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
